export const keywordsSet: string[][] = [
    ["make the video"],
    ["make an video"],
    ["make a video"],
    ["make video"],
    ["make the vid"],
    ["make an vid"],
    ["make a vid"],
    ["make vid"],
    ["make the tutorial"],
    ["make an tutorial"],
    ["make a tutorial"],
    ["make tutorial"],
    ["make the episode"],
    ["make an episode"],
    ["make a episode"],
    ["make episode"],
    ["make the show"],
    ["make an show"],
    ["make a show"],
    ["make show"],
    ["make the content"],
    ["make an content"],
    ["make a content"],
    ["make content"],
    ["make the documentary"],
    ["make an documentary"],
    ["make a documentary"],
    ["make documentary"],
    ["make the course"],
    ["make an course"],
    ["make a course"],
    ["make course"],
    ["make ", "video"],
    ["make ", "tutorial"],
    ["make ", "episode"],
    ["make ", "content"],
    ["make ", "documentary"],
    ["make ", "course"],
    ["make ", " vid"],
    ["do the video"],
    ["do an video"],
    ["do a video"],
    ["do video"],
    ["do the vid"],
    ["do an vid"],
    ["do a vid"],
    ["do vid"],
    ["do the episode"],
    ["do an episode"],
    ["do a episode"],
    ["do episode"],
    ["do the content"],
    ["do an content"],
    ["do a content"],
    ["do content"],
    ["do you have a video"],
    ["do the documentary"],
    ["do an documentary"],
    ["do a documentary"],
    ["do documentary"],
    ["do ", "video"],
    ["do ", "episode"],
    ["do ", "content"],
    ["do ", "documentary"],
    ["do ", " vid"],
    ["create the vid"],
    ["create an vid"],
    ["create a vid"],
    ["create vid"],
    ["create the episode"],
    ["create an episode"],
    ["create a episode"],
    ["create episode"],
    ["create the content"],
    ["create an content"],
    ["create a content"],
    ["create content"],
    ["create the documentary"],
    ["create an documentary"],
    ["create a documentary"],
    ["create documentary"],
    ["create ", "video"],
    ["create ", "episode"],
    ["create ", "content"],
    ["create ", "documentary"],
    ["create ", " vid"],
    ["post the video"],
    ["post an video"],
    ["post a video"],
    ["post video"],
    ["post the vid"],
    ["post an vid"],
    ["post a vid"],
    ["post vid"],
    ["post the episode"],
    ["post an episode"],
    ["post a episode"],
    ["post episode"],
    ["post the content"],
    ["post an content"],
    ["post a content"],
    ["post content"],
    ["post the documentary"],
    ["post an documentary"],
    ["post a documentary"],
    ["post documentary"],
    ["post ", "video"],
    ["post ", "episode"],
    ["post ", "content"],
    ["post ", "documentary"],
    ["post ", " vid"],
    ["how about vid"],
    ["how about", " vid"],
    ["how about", "episode"],
    ["how about", "content"],
    ["how about"],
    ["need a vid"],
    ["need vid"],
    ["need an episode"],
    ["need episode"],
    ["need a content"],
    ["need content"],
    ["need", " vid"],
    ["request vid"],
    ["request", " vid"],
    ["request", "episode"],
    ["request", "content"],
    ["please explain"],
    ["should talk about"],
    ["should explain"],
    ["should do one on"],
    ["should make one on"],
    ["should create one on"],
    ["should post one on"],
    ["should do one covering"],
    ["should make one covering"],
    ["should create one covering"],
    ["should post one covering"],
    ["should do one explaining"],
    ["should make one explaining"],
    ["should create one explaining"],
    ["should post one explaining"],
    ["can you have a video about"],
    ["should be done"],
    ["could you"],
    ["can you"],
    ["would be", "nice"],
    ["would like to see"],
    ["can you provide"],
    ["please do"],
    ["please make"],
    ["please teach"],
    ["please cook"],
    ["please review"],
    ["please", "tutorial"],
    ["tutorial", "please"],
    ["please", "course"],
    ["course", "please"],
    ["you should do"],
    ["you should make"],
    ["interested in seeing"],
    ["interested in watching"],
    ["you could do"],
    ["perform an analysis"],
];
