import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

export const getComments = async (
    apiKey: string,
    videoId: string,
    pageToken?: string,
) => {
    let url: string = `https://www.googleapis.com/youtube/v3/commentThreads?key=${apiKey}&videoId=${videoId}&part=snippet&maxResults=100${
        pageToken ? `&pageToken=${pageToken}` : ""
    }`;

    try {
        const response = await axios.get(url);
        return response.data;
    } catch (error: any) {
        throw error.response.data;
    }
};
