import React from "react";
import CommentListItem from "./CommentsListItem/CommentsListItem";
import Comment from "../../models/Comment";

type Props = {
    items: Comment[];
    setTruePositive: (id: string) => void;
    setFalsePositive: (id: string) => void;
};

const CommentsList = ({ items, setTruePositive, setFalsePositive }: Props) => {
    return (
        <ul className="list-group">
            {items.map((item) => (
                <CommentListItem
                    key={item.id}
                    item={item}
                    setTruePositive={setTruePositive}
                    setFalsePositive={setFalsePositive}
                />
            ))}
        </ul>
    );
};

export default CommentsList;
