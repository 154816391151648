import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

export const getVideo = async (apiKey: string, videoId: string) => {
    let url: string = `https://www.googleapis.com/youtube/v3/videos?key=${apiKey}&id=${videoId}&part=snippet`;

    try {
        const response = await axios.get(url);
        return response.data;
    } catch (error: any) {
        throw error.response.data;
    }
};
